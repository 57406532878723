<template>
  <div>
    <p class="font-weight-bold text-h6 ma-0">
      {{ category.description }}
    </p>
    <v-row class="px-10 pt-6 pb-10">
      <v-col
        v-for="(cat, index) in categories"
        :key="index"
        cols="12"
      >
        <v-btn
          x-large
          outlined
          elevation="0"
          block
          class="justify-start"
          @click="select(cat)"
        >
          {{ cat.categoryName }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'InquirySecondPage',
  props: {
    category: Object
  },
  mounted() {
    this.search()
  },
  data() {
    return {
      categories: [],
      subCategories: {
        'A': [
          { value: 'A1', text: '로그인에 문제가 있음'},
          { value: 'A2', text: '검색이 안됨'},
          { value: 'A3', text: '버튼 작동이 안됨'},
          { value: 'A4', text: '페이지가 안 열리거나 로딩바가 계속 돌아감'},
          { value: 'A5', text: '다운로드나 리스트 출력에 오류가 있음'},
          { value: 'A6', text: '기타 오류'},
        ],
        'B': [
          { value: 'B1', text: '특허 서지나 청구항 등 정보 오류'},
          { value: 'B2', text: '찾는 특허가 없음'},
          { value: 'B3', text: '번역 오류'},
          { value: 'B4', text: '기타 오류'},
        ],
        'C': [
          { value: 'C1', text: '일반 검색'},
          { value: 'C2', text: '선행조사용 검색'},
          { value: 'C3', text: '번호 검색'},
          { value: 'C5', text: '기타'},
        ],
        'D': [
          { value: 'D1', text: '일반 검색'},
          { value: 'D2', text: '선행조사용 검색'},
          { value: 'D3', text: '번호 검색'},
          { value: 'D5', text: '기타'},
        ],
      }
    }
  },
  watch: {
    category: {
      handler() {
        this.search()
      },
      deep: true
    }
  },
  methods: {
    search() {
      this.$http.get('/api/sys/chatbot/slaves', {params: {parentId: this.category.categoryId, useYn: 'Y'}})
          .then(({ data }) => {
            this.categories = data
          })
    },
    select(subCategory) {
      this.$emit('next', subCategory)
    }
  }
}
</script>
