<template>
  <div>
    <p class="font-weight-bold text-h6 ma-0 text-left px-10">
      {{ subCategory.categoryName }}
    </p>
    <v-row class="px-10 pt-5">
      <v-col cols="12" class="pb-0">
        <v-textarea
          v-model="item.content"
          class="inquiry-textarea"
          outlined
          dense
          placeholder="오류 또는 불편하신 점을 자유롭게 적어주세요."
          :error="errContent"
          :error-messages="errContent"
          rows="5"
        ></v-textarea>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-text-field
          v-model="item.email"
          outlined
          dense
          placeholder="이메일"
          class="inquery-text"
          :error="errEmail"
          :error-messages="errEmail"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-text-field
          v-model="item.tel"
          outlined
          dense
          placeholder="휴대전화번호"
          class="inquery-text"
          :error="errTel"
          :error-messages="errTel"
        />
      </v-col>
      <v-col cols="12">
        <v-file-input v-model="files" clearable multiple outlined dense hide-details placeholder="첨부파일" />
      </v-col>
      <v-col cols="12">
        <div>이메일, 휴대전화 확인 필수!</div>
        <div>답변 내용은 이메일을 통해 확인 가능합니다.</div>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-end"
      >
        <v-btn
          color="#b72654"
          dark
          elevation="0"
          @click="send"
        >
          보내기
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'InquiryThirdPage',
  props: {
    category: Object,
    subCategory: Object
  },
  data() {
    return {
      item: {
        content: null,
        email: null,
        tel: null,
        categoryCode: null,
        categoryText: null,
        subCategoryCode: null,
        subCategoryText: null,
        userId: null,
      },
      files: [],
      errContent: null,
      errEmail: null,
      errTel: null,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
  },
  mounted() {
    this.item.email = this.userInfo.email
    this.item.tel = this.userInfo.cellNo
    this.item.userId = this.userInfo.userCode
    this.item.categoryCode = this.category.categoryId
    this.item.categoryText = this.category.categoryName
    this.item.subCategoryCode = this.subCategory.categoryId
    this.item.subCategoryText = this.subCategory.categoryName
  },
  methods: {
    send() {
      if (this._validate()) {
        let formData = new FormData();
        for (let file of this.files) {
          formData.append("files", file)
        }

        formData.append("param", new Blob([ JSON.stringify(this.item) ], {type : "application/json"}))

        this.$http.post('/api/inquiry', formData)
          .then(({ data }) => {
            if (data === 'success') {
              this.$emit('done')
            }
          })
      }
    },
    _validate() {
      let valid = true
      this.errContent = null
      this.errEmail = null
      this.errTel = null
      if (!this.item.content) {
        valid = false
        this.errContent = '문의 내용을 입력해주세요'
      }
      if (!this.item.email) {
        valid = false
        this.errEmail = '이메일을 입력해주세요'
      }
      if (!this.item.tel) {
        valid = false
        this.errTel = '휴대전화번호를 입력해주세요'
      }
      return valid
    }
  }
}
</script>

<style>
.inquiry-textarea textarea {
  height: auto;
}
.inquery-text input[type=text] {
  text-align: left;
}
</style>
