<template>
  <div>
    <p class="font-weight-bold text-h6 ma-0">사용 중 오류 또는 불편한 점이<br>발생한 카테고리를 선택해주세요.</p>
    <v-row class="pa-10">
      <v-col
        v-for="(cat, index) in categories"
        :key="index"
        cols="6"
      >
        <v-btn
          x-large
          outlined
          elevation="0"
          block
          height="80"
          @click="select(cat)"
        >
          {{ cat.categoryName }}
        </v-btn>
      </v-col>
    </v-row>
    <p class="font-weight-bold text-h6">오류 또는 문의가 접수되면<br>신속하게 답변드립니다.</p>
  </div>
</template>

<script>
export default {
  name: 'InquiryFirstPage',
  data() {
    return {
      categories: []
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      this.$http.get('/api/sys/chatbot/masters', {params: {useYn: 'Y'}})
          .then(({ data }) => {
            this.categories = data
          })
    },
    select(category) {
      this.$emit('next', category)
    }
  }
}
</script>
