<template>
  <v-card>
    <v-toolbar flat dark color="#b72654">
      <v-btn v-if="tab > 0" icon dark @click="tab = tab - 1">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer/>
      <v-toolbar-title class="font-weight-bold">{{ tab > 0 ? category.description : '문의' }}</v-toolbar-title>
      <v-spacer/>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text style="min-height: 530px;">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <inquiry-first-page @next="onSelectCategory"/>
        </v-tab-item>
        <v-tab-item>
          <inquiry-second-page :category="category" @next="onSelectSubCategory"/>
        </v-tab-item>
        <v-tab-item>
          <inquiry-third-page :category="category" :sub-category="subCategory" @done="onDone"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import InquiryFirstPage from "@/views/inquiry/InquiryFirstPage.vue";
import InquirySecondPage from "@/views/inquiry/InquirySecondPage.vue";
import InquiryThirdPage from "@/views/inquiry/InquiryThirdPage.vue";

export default {
  name: 'InquiryMenu',
  components: {InquiryThirdPage, InquirySecondPage, InquiryFirstPage},
  data() {
    return {
      tab: 0,
      category: null,
      subCategory: null
    }
  },
  methods: {
    onSelectCategory(category) {
      this.category = category
      this.tab = 1
    },
    onSelectSubCategory(subCategory) {
      this.subCategory = subCategory
      this.tab = 2
    },
    onDone() {
      alert('저희와 소통해주셔서 감사합니다.')
      this.$emit('close')
    },
    close() {
      this.tab = -1
      this.$emit('close')
      this.tab = 0
    }
  }
}
</script>
