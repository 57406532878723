<template>
  <v-app class="main-wrap">
    <v-navigation-drawer
      app
      clipped
      permanent
      fixed
      :width="200"
      :mini-variant.sync="minimize"
      color="#455184"
      @transitionend="onTransitionEnd"
    >
      <div class="my-2 mb-0 px-2 text-right">
        <v-btn
          x-small
          height="30px"
          @click.stop="toggleMini"
        >
          <v-icon v-if="!minimize">mdi-chevron-left</v-icon>
          <v-icon v-if="minimize">mdi-menu</v-icon>
        </v-btn>
      </div>
      <v-list v-if="showMenu">
        <v-list-item
            class="side-menu"
            to="/main"
            router
            exact
            active-class="menu-active"
        >
          <v-list-item-avatar
              size="20"
              tile
          >
            <v-icon dark>mdi-view-dashboard</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="'HOME'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            class="side-menu"
            to="/notice"
            router
            exact
            active-class="menu-active"
        >
          <v-list-item-avatar
              size="20"
              tile
          >
            <v-icon dark>mdi-bulletin-board</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="'공지사항'" />
          </v-list-item-content>
        </v-list-item>
        <v-subheader
          class="mt-4 nav-subheader"
        >
          기술이전
        </v-subheader>
        <v-list-item
          v-for="menu in menuList"
          :key="menu.menuId"
          class="pl-8 side-menu"
          active-class="menu-active"
          :to="menu.menuLink"
          router
          exact
        >
          <v-list-item-content>
            <v-list-item-title v-text="menu.menuName" />
          </v-list-item-content>
        </v-list-item>

        <v-subheader
          class="nav-subheader mt-4"
        >
          기술료
        </v-subheader>
        <v-list-item
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/contract/payment/view"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'조회'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin || isManager || isTeamLeader"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/contract/payment/manage"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'관리'" />
          </v-list-item-content>
        </v-list-item>

        <v-subheader
          v-if="isAdmin || isManager || isTeamLeader"
          class="nav-subheader mt-4"
        >
          관리
        </v-subheader>
        <v-list-item
          v-if="isAdmin"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/sys/auth"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'권한 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="isAdmin"
            class="pl-8 side-menu"
            active-class="menu-active"
            to="/sys/user"
            router
            exact
            color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'사용자 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/sys/menu"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'메뉴 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/sys/lab"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'연구소 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/sys/company"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'업체 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/sys/inventor"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'발명자 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin || isManager || isTeamLeader"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/sys/events"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'일정 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="isAdmin"
            class="pl-8 side-menu"
            active-class="menu-active"
            to="/sys/alarm"
            router
            exact
            color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'안내메일 문구 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="isAdmin"
            class="pl-8 side-menu"
            active-class="menu-active"
            to="/sys/chatbot"
            router
            exact
            color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'쳇봇 카테고리 관리'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin"
          class="pl-8 side-menu"
          active-class="menu-active"
          to="/sys/code"
          router
          exact
          color="#b8b8b9"
        >
          <v-list-item-content>
            <v-list-item-title v-text="'코드 관리'" />
          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item-->
<!--          v-if="isAdmin"-->
<!--          class="pl-8 side-menu"-->
<!--          active-class="menu-active"-->
<!--          to="/outcall"-->
<!--          router-->
<!--          exact-->
<!--          color="#b8b8b9"-->
<!--        >-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title v-text="'접속 통계'" />-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="white"
      :elevation="1"
      clipped-left
      fixed
      flat
    >
      <img
          class="shrink ml-6 mr-2"
          :src="require('@/assets/img/top-logo.png')"
          alt="충북대학교"
          height="80%"
      />

      <v-spacer></v-spacer>
      <span class="mr-4">{{ userInfo.userName }}님 환영합니다</span>
      <v-btn
        target="_blank"
        color="#2c3976"
        large
        text
        tile
        @click="signOut"
      >
        <span class="logout-title mr-2">로그아웃</span>
        <v-icon large color="black">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main ref="mm" class="mm mb-10">
      <router-view/>

      <v-menu
        v-model="inquiryMenu"
        :close-on-click="false"
        :close-on-content-click="false"
        max-width="500"
        min-width="450"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#b72654"
            dark
            fixed
            bottom
            right
            fab
            class="mb-16"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chat-question-outline</v-icon>
          </v-btn>
        </template>

        <inquiry-menu v-if="inquiryMenu" @close="inquiryMenu = false"/>
      </v-menu>
    </v-main>
    <v-footer app fixed inset color="#f3f4f8" class="py-3" style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
      <a href="#">
        <img
          :src="require('@/assets/img/footer-logo.png')"
          alt="충북대학교"
          height="50px"
          style="vertical-align: middle"
        />
      </a>
      <v-divider class="ml-16" vertical />
      <span class="ml-8">충북 청주시 서원구 충대로 1, NH관(E3-1) 3층<br>
                        충북대학교 산학협력단 기술사업화팀: 043-261-3963</span>
      <div class="ml-auto">
        <a href="https://iacf.cbnu.ac.kr/sso/sso_proc.jsp?code=31" target="_blank"><img style="width: 200px" :src="require('@/assets/img/banner01.png')"></a>
        <a href="https://iacf.cbnu.ac.kr/sso/sso_proc.jsp?code=14" target="_blank"><img style="width: 200px" :src="require('@/assets/img/banner02.png')"></a>
        <a href="http://tmarket.cbnu.ac.kr/main.jsp" target="_blank"><img style="width: 168px" :src="require('@/assets/img/banner03.png')"></a>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex"
import InquiryMenu from "@/views/inquiry/InquiryMenu.vue";
export default {
  name: 'Main',
  components: {InquiryMenu},
  data () {
    return {
      minimize: false,
      showMenu: true,
      showFooter: false,
      inquiryMenu: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      menuList: 'menuList',
      isAdmin: 'isAdmin',
      isManager: 'isManager',
      isTeamLeader: 'isTeamLeader'
    })
  },
  watch: {
    '$route.path' (val) {
      this.showFooter = val.indexOf('main') < 0
      if (val.indexOf('main') < 0) {
        window.removeEventListener('scroll', this.scrollEvent);
      } else {
        window.addEventListener('scroll', this.scrollEvent);
      }
    }
  },
  mounted() {
    if (this.$route.path.indexOf('main') >= 0) {
      window.addEventListener('scroll', this.scrollEvent);
    }
    this.showFooter = this.$route.path.indexOf('main') < 0
  },
  methods: {
    toggleMini () {
      this.showMenu = false
      this.minimize = !this.minimize
    },
    onTransitionEnd () {
      this.showMenu = !this.minimize
    },
    signOut () {
      this.$store.dispatch('signOut')
      .then(() => {
        window.location.href = '/'
      })
    },
    scrollEvent() {
      const body = document.body
      const html = document.documentElement

      const documentHeight = Math.max( body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight )
      const windowHeight = window.innerHeight
      this.showFooter = Math.round(window.scrollY) === (documentHeight - windowHeight)
    }
  }
}
</script>

<style>
.app-bar-border {
  border-bottom: 1px solid #d2d2d2 !important;
}
.logout-title {
  font-size: 17px;
  font-weight: 700;
}
.nav-subheader {
  font-size: 17px !important;
  font-weight: 700 !important;
  color: white !important;
}
.side-menu {
  color: #b8b8b9 !important;
}
.menu-active {
  background-color: #ffffff;
  color: #455184 !important;
}
.main-wrap {
  background-color: #f3f4f8 !important;
}
.v-main__wrap {
  background-color: #f3f4f8 !important;
}
</style>
